.OfferPage {
    flex-grow: 1;
}

.OfferPage-Text a{
    color: inherit;
    display: inline;
}

.OfferPage-Text {
    text-align: justify;
    margin: 70px auto 0;
    padding: 10px;
    font-family: "Lato Light", Arial, sans-serif;
    max-width: 1400px;
}

.OfferPage-Text h2 {
    font-family: "Lato Heavy", Arial, sans-serif;
    text-align: left;
}

.OfferPage-Text li {
    margin-bottom: 10px;
}

.OfferPage-Text div {
    margin-left: 20px;
    margin-bottom: 10px;
}

@media (min-width: 720px) {
    .OfferPage-Text {
    }
}

@media (min-width: 1200px) {
    .OfferPage-Text {
        padding: 0;
        margin-top: 100px;
    }
}
