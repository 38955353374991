.Menu {
    min-width: 240px;
    font-family: "Arial Narrow", Arial, sans-serif;
    font-size: 22px;
    width: 100%;
    padding-top: 47px;
}

.Menu-Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.Menu_visible {
    display: block;
}

.Menu-List {
    width: 50%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 20px 0 20px 20px;
    background-color: #fff;
}

.Menu-Link,
.Menu-Link:hover,
.Menu-Link:active {
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 35px;
    height: 60px;
    line-height: 60px;
    color: inherit;
    position: relative;
}

.Menu-Item,
.Menu-Item:hover,
.Menu-Item:active {
    position: relative;

}

.Menu-Item_type_pressa-ru-ua-pdf:before {

    background-image: url('../Icons/magazines.svg');
}

.Menu-Item_type_pro-sport:before {
    background-image: url('../Icons/sport.svg');
}

.Menu-Item_type_pro-avtomobili:before {
    background-image: url('../Icons/auto.svg');
}

.Menu-Item_type_pro-stil:before {
    background-image: url('../Icons/style.svg');
}

.Menu-Item_type_pro-gadzhety:before {
    background-image: url('../Icons/gadgets.svg');
}

.Menu-Item_type_pro-otdykh:before {
    background-image: url('../Icons/relaxation.svg');
}

.Menu-Item_type_pro-kosmos:before {
    background-image: url('../Icons/space.svg');
}

.Menu-Item_type_cookery:before {
    background-image: url('../Icons/food.svg');
}

.Menu-Item:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Menu-Item:hover,
.Menu-Item:active {
    filter: invert(9%) sepia(100%) saturate(6978%) hue-rotate(354deg) brightness(93%) contrast(98%);
}

@media (max-width: 600px) {
    .Menu-List {
        width: 70%;
    }
}


@media (min-width: 1200px) {
    .Menu {
        width: 100%;
        height: 60px;
        padding-top: 0;
        margin-left: 10%;
        margin-right: 10%;
        color: #000;
        font-weight: 500;
        font-size: 12px;
        font-family: "Roboto Slab Regular";
        text-transform: uppercase;
    }

    .Menu-Wrapper {
        width: 900px;
        flex-direction: row;
        background-color: transparent;
    }

    .Menu-List {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0;
        padding: 0;
        height: 70px;

        background-color: transparent;
    }

    .Menu-Item {
        flex-grow: 1;
        padding: 0 0 0 15px;
    }

    .Menu-Item:before {
        display: none;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        height: 70px;
        line-height: 70px;
        padding: 10px 0 0 0;
    }

    .Menu-ItemLink-Text {
        font-family: "Roboto-Bold", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-transform: none;
        line-height: 50px;
        text-align: center;
    }

    .Menu-Link:hover .Menu-ItemLink-Text,
    .Menu-Link:active .Menu-ItemLink-Text {
        border-bottom: 5px solid #E30611;
    }
}
