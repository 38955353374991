.PrivacyPage {
    flex-grow: 1;
}

.PrivacyPage-Text a{
    color: inherit;
    display: inline;
}

.PrivacyPage-Text {
    text-align: justify;
    margin: 70px auto 0;
    padding: 10px;
    font-family: "Lato Light", Arial, sans-serif;
    max-width: 1400px;
}

.PrivacyPage-Text h2 {
    font-family: "Lato Heavy", Arial, sans-serif;
    text-align: left;
}

.PrivacyPage-Text div {
    margin-bottom: 10px;
}

.PrivacyPage-Text table {
    border-collapse: collapse;
    thead {
        text-align: center;
    }
    td {
        border: 1px solid currentColor;
        padding: 5px;
        text-align: left;
    }
}
.PrivacyPage-Text ol {
    list-style: none;
    counter-reset: li;
    padding: 0;
    margin: 0;
}

.PrivacyPage-Text ol > li:before {
    display: inline;
    counter-increment: li;
    content: counters(li,".") ". ";
    font-weight: bold
}

.PrivacyPage-Text ul {
    margin: 0
}

.PrivacyPage-Text li {
    text-align: justify;
    margin-bottom: 15px;
}
.PrivacyPage-Text li span:has(table) {
    display: block;
    overflow: auto;
}

@media (min-width: 720px) {
    .PrivacyPage-Text {
    }
}

@media (min-width: 1200px) {
    .PrivacyPage-Text {
        padding: 0;
        margin-top: 100px;
    }
}
