@keyframes buzzOut {
  3% {
    transform: translateX(3px) rotate(2deg);
  }
  6% {
    transform: translateX(-3px) rotate(-2deg);
  }
  9% {
    transform: translateX(3px) rotate(2deg);
  }
  12% {
    transform: translateX(-3px) rotate(-2deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  18% {
    transform: translateX(-2px) rotate(-1deg);
  }
  21% {
    transform: translateX(2px) rotate(1deg);
  }
  24% {
    transform: translateX(-2px) rotate(-1deg);
  }
  27% {
    transform: translateX(1px) rotate(0);
  }
  30% {
    transform: translateX(-1px) rotate(0);
  }
}

.PromoBanner {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.PromoBanner-Swiper {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.PromoBannerItem {
  position: relative;
  font-family: "Times New Roman", Times, serif;
  display: flex;
  flex-flow: row nowrap;
  height: 120px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.PromoBannerItem.PromoBannerItem_Reverse {
  flex-flow: row-reverse nowrap;
}

.PromoBannerItem-Image {
  object-fit: cover;
  object-position: center;
  background: #eee;
}

.PromoBannerItem-Caption {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  width: 155px;
  color: #fff;
  line-height: 1.2;
  z-index: 10;
}

.PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink {
  position: absolute;
  inset: 0;
  width: 100%;
}

.PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink .PromoBannerItem-CaptionDescription {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 30px;
  justify-content: flex-end;
  padding: 0 8px 0 0;
  white-space: nowrap;
  font-size: 15px;
}

.PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink .PromoBannerItem-CaptionDescriptionBr {
  display: none;
}

.PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink .PromoBannerItem-CaptionTitle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 30px;
  width: 150px;
  height: unset;
}

.PromoBannerItem-CaptionTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  height: 50%;
  background: #282828;
}

.PromoBannerItem-CaptionTitle svg {
  width: auto;
  height: 19px;
}

.PromoBannerItem-CaptionDescription {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  flex-shrink: 1;
  font-size: 16px;
  background: #ea2323;
}

.PromoBannerItem-CaptionTitle.PromoBannerItem-CaptionTitle_Transparent {
  background: transparent;
  color: #282828;
}

.PromoBannerItem-CaptionTitle.PromoBannerItem-CaptionTitle_SemiTransparent {
  background: rgba(40, 40, 40, 0.2);
  color: #fff;
}

.PromoBannerItem-CaptionTitleLink {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 50px;
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
}

.PromoBannerItem-CaptionTitleLinkText {
  animation: buzzOut 2.7s infinite;
}

@media (min-width: 720px) {
  .PromoBannerItem {
    height: 144px;
  }

  .PromoBannerItem.PromoBannerItem_Reverse .PromoBannerItem-Caption.PromoBannerItem-Caption_TitleAbsolute .PromoBannerItem-CaptionTitle {
    left: unset;
    right: 100%;
  }

  .PromoBannerItem-Caption {
    flex-flow: row nowrap;
    width: 404px;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink {
    position: relative;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink .PromoBannerItem-CaptionTitle {
    position: static;
    width: unset;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink .PromoBannerItem-CaptionDescription {
    height: 100%;
    justify-content: center;
    padding: unset;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink .PromoBannerItem-CaptionDescriptionBr {
    display: block;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_Reverse {
    flex-flow: row-reverse nowrap;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_TitleAbsolute {
    width: 224px;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_TitleAbsolute .PromoBannerItem-CaptionTitle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 75%;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_TitleAbsolute .PromoBannerItem-CaptionDescription {
    width: 100%;
  }

  .PromoBannerItem-CaptionTitle {
    width: 40%;
    height: 100%;
  }

  .PromoBannerItem-CaptionTitle svg {
    height: 24px;
  }

  .PromoBannerItem-CaptionDescription {
    width: 60%;
    height: 100%;
  }

  .PromoBannerItem-CaptionDescription,
  .PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink .PromoBannerItem-CaptionDescription {
    font-size: 24px;
  }

  .PromoBannerItem-CaptionTitleLink {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .PromoBannerItem {
    height: 192px;
  }

  .PromoBannerItem-Caption {
    width: 530px;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_TitleAbsolute {
    width: 474px;
  }

  .PromoBannerItem-Caption.PromoBannerItem-Caption_TitleAbsolute .PromoBannerItem-CaptionTitle {
    width: 55%;
  }

  /*.PromoBannerItem-CaptionTitle {*/
  /*  width: 35%;*/
  /*}*/

  .PromoBannerItem-CaptionTitle svg {
    height: 26px;
  }

  .PromoBannerItem-CaptionTitleLink {
    font-size: 28px;
    padding-top: 75px;
  }

  /*.PromoBannerItem-CaptionDescription {*/
  /*  width: 65%;*/
  /*}*/

  .PromoBannerItem-CaptionDescription,
  .PromoBannerItem-Caption.PromoBannerItem-Caption_WithLink .PromoBannerItem-CaptionDescription {
    font-size: 38px;
  }
}

