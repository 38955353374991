.CategoryItem {
    display: flex;
    justify-content: center;
}

.CategoryItem-Wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1600px;
}

.CategoryItem_theme_dark .CategoryItem-Wrapper {
     max-width: 100%;
     padding: 0;
}

.MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {
    height: 530px;
}

.CategoryItem-ContentWrap {
    /* width: 100%; */
    max-width: 1440px;
    margin: auto;
}

.CategoryItem-HomeLink_ofPage_main {
    display: none;
}

.CategoryItem-Title {
    position: relative;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #000;
    margin-left: 26px;
}

.CategoryItem-type_pressa-ru-ua-pdf{
    color: #fff;
}

.CategoryItem-type_pressa-ru-ua-pdf:before {
    background-image: url('../Icons/magazinesWhite.svg');
}

.CategoryItem-type_pro-sport:before {
    background-image: url('../Icons/sport.svg');
}

.CategoryItem-type_pro-avtomobili:before {
    background-image: url('../Icons/auto.svg');
}

.CategoryItem-type_pro-stil:before {
    background-image: url('../Icons/style.svg');
}

.CategoryItem-type_pro-gadzhety:before {
    background-image: url('../Icons/gadgets.svg');
}

.CategoryItem-type_pro-otdykh:before {
    background-image: url('../Icons/relaxation.svg');
}

.CategoryItem-type_pro-kosmos:before {
    background-image: url('../Icons/space.svg');
}

.CategoryItem-type_cookery:before {
    background-image: url('../Icons/food.svg');
}

.CategoryItem-type_music:before {
    background-image: url('../Icons/music.svg');
}


.CategoryItem-Title:before {
    fill: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -30px;
    content: '';
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.CategoryPage .CategoryItem-Title::before {
    display: none;
}

/*разные фоны категорий */

.CategoryItem_theme_dark,
.CategoryItem:first-child {
    padding: 0;
    background: #3B454E;
}

.CategoryItem {
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%);
 }

.CategoryItem_theme_light{
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%) !important;
}

.CategoryItem-TitleLink {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
    font-family: "Arial Bold" , sans-serif;
}

/*кнопка больше*/

.CategoryItem-MoreButtonLink {
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: inherit;
    position: relative;
}

.CategoryItem_theme_light .CategoryItem-MoreButtonLink {
    margin-bottom: 30px;
}


.CategoryItem-MoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    margin: 0 auto;
    border-radius: 6px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
}

.CategoryItem .CategoryItem-MoreButton {
    background-color: #000;
    color: #fff;
}

.CategoryItem:first-child .CategoryItem-MoreButton {
    background-color: #E30611;
    color: #fff;
}

/*категория на странице категорий */
.CategoryItem_ofPage_category {
    background-color: #f6f6f6;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark {
    background-color: #3B454E;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S {
    /*filter: invert(100%);*/
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {
    color: #ffffff;
}

.CategoryItem-MoreButtonWrapper{
    margin: auto;
}

.CategoryItem-LinkWrap {
    width: 100%;
    max-width: 1440px;
    align-self: center;
    position: relative;
    padding: 0 20px;
}

/*кнопка домой*/
.CategoryItem-HomeLink_ofPage_category {
    text-decoration: none;
    display: block;
    align-self: center;
    z-index: 1;
}

.CategoryItem-HomeLink-Btn_size_S {
    height: 30px;
    width: 30px;
    background-image: url('../Icons/backBlack.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    position: relative;
}

.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S:nth-child(1) {
    background-image: url('../Icons/back.svg');
}

.MainPage-Content .CategoryItem-CategoryLink {
    height: 30px;
    width: 30px;
    background-image: url('../Icons/arrowMainPage.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;
    margin-left: 10px;
    margin: auto 0;

}

.CategoryItem:first-child .CategoryItem-CategoryLink {
    display: none;
}


@media (max-width: 1200px) {
    .CategoryPage-Content .CategoryItem-LinkWrap {
        display: flex;
    }
}

@media (max-width: 800px) {

    .MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {
        height: 468px;
        width: 100%;
    }

    .CategoryItem-ContentWrap {
        width: 100%;
        margin-bottom: 30px;
    }

    .CategoryItem-LinkWrap {
        width: 100%;
    }

    .CategoryPage .CategoryItem-LinkWrap {
        padding: 0;
    }

    .CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {
        margin-left: 4px;
    }



    .CategoryItem-HomeLink_ofPage_category {
        margin-left: 12px;
    }

    .CategoryPage .CategoryItem-MoreButtonWrapper {
        display: none;
    }

    .CategoryItem-MoreButton {
        width: 240px;
        height: 60px;
        font-size: 18px;
    }

}

@media (max-width: 600px) {

    .CategoryItem-MoreButton {
        width: 180px;
        height: 40px;
        font-size: 14px;
    }

    .MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {
        height: 400px;
    }

}

@media (max-width: 1200px) {

    .CategoryItem_theme_light .CategoryItem-MoreButtonWrapper {
        margin-top: 20px;
    }

}

@media (min-width: 720px) {



    .CategoryItem_theme_dark .CategoryItem-MoreButtonLink {
        bottom: 0;
    }




    .CategoryItem-HomeLink-Btn_size_L {
        display: block;
        text-transform: lowercase;
        font-family: "Arial Narrow Bold", Arial, sans-serif;
        font-size: 20px;
    }


}

@media (min-width: 1200px) {



    .MainPage-Content .CategoryItem-CategoryLink {
        display: none;
    }

    .CategoryPage .CategoryItem-Title::before {
        display: block;

    }

    .CategoryItem-HomeLink.CategoryItem-HomeLink_ofPage_category {
        display: none;
    }

    .CategoryItem-Title:before {
        display: block;
        top: 50%;
    }

    .CategoryItem-HomeLink-Btn_size_S {
        display: none;
    }

    .CategoryItem-MoreButton {
        max-width: 180px;
        height: 40px;
        font-size: 14px;
    }

}
